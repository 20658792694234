<template>
  <div v-if="productsRef.length">
    <PagePartsHeadText
      v-if="title"
      :title="title"
      :sub-title="subTitle"
      :pc-title-font-size-px="pcTitleFontSizePx"
      :pc-sub-title-font-size-px="pcSubTitleFontSizePx"
      :sp-title-font-size-px="spTitleFontSizePx"
      :sp-sub-title-font-size-px="spSubTitleFontSizePx"
      :class="[$style.head_text, { [$style.no_padding]: noPadding }]"
    />
    <div :class="[$style.product_browsing_history_area, { [$style.no_padding]: noPadding }]">
      <PagePartsProductListItemCarousel
        :products="productsRef"
        :class="$style.carousel_container"
        :is-horizontal="isHorizontal"
        :is-vertical="isVertical"
        :is-sp-column="isSpColumn"
        :button-variant="buttonVariant"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { VariantType } from "@tential/consumer-component/type"
import { PagePartsProductType } from "~/types/product"
import { useStore } from "~/stores"
import { getSiteProductPageUrl } from "~/utils/functions/product"

withDefaults(
  defineProps<{
    title?: string
    subTitle?: string
    pcTitleFontSizePx?: number
    spTitleFontSizePx?: number
    pcSubTitleFontSizePx?: number
    spSubTitleFontSizePx?: number
    noPadding?: boolean
    isHorizontal?: boolean
    isVertical?: boolean
    isSpColumn?: boolean
    /** ボタンスタイル */
    buttonVariant?: VariantType
  }>(),
  {
    title: "",
    subTitle: "",
    pcTitleFontSizePx: undefined,
    spTitleFontSizePx: undefined,
    pcSubTitleFontSizePx: undefined,
    spSubTitleFontSizePx: undefined,
    noPadding: false,
    isHorizontal: false,
    isVertical: false,
    isSpColumn: false,
    buttonVariant: undefined,
  },
)

const productsRef = ref<PagePartsProductType[]>([])
const store = useStore()

const getProductLink = (product: (typeof store.productBrowsingHistory)[number]): string => {
  return getSiteProductPageUrl(product)
}

onMounted(() => {
  productsRef.value = store.productBrowsingHistory.map((prod): PagePartsProductType => {
    return {
      ...prod,
      url: getProductLink(prod),
    }
  })
})
</script>

<style scoped module lang="scss">
.head_text {
  &.no_padding {
    padding: 0;
  }
}
.product_browsing_history_area {
  @include container-lg;
  margin-top: 1.75rem;
  @include md {
    margin-top: 2.5rem;
  }
  &.no_padding {
    padding: 0;
  }
}
</style>
