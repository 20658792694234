<template>
  <section :class="$style.root">
    <MoleculesConsumerHeading
      v-if="md"
      :title="title ?? ''"
      :sub-title="subTitle"
      :title-font-size-px="pcTitleFontSizePx"
      :sub-title-font-size-px="pcSubTitleFontSizePx"
      variant="sub"
      :align="align"
    />
    <div v-else :class="$style.sp_container">
      <h2 :class="$style.title" :style="spTitleStyle" v-html="sanitizedTitle" />
      <p :class="$style.sub_title" :style="spSubTitleStyle" v-html="sanitizedSubTitle" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { sanitizeHtmlContent } from "@tential/ec-gql-schema/utils/functions/sanitize"
import { type MoleculesConsumerHeadingProps } from "@tential/consumer-component/type"
type Props = {
  title: string | undefined
  subTitle: string | undefined
  pcTitleFontSizePx?: number
  spTitleFontSizePx?: number
  pcSubTitleFontSizePx?: number
  spSubTitleFontSizePx?: number
  align?: MoleculesConsumerHeadingProps["align"]
}

const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  subTitle: undefined,
  pcTitleFontSizePx: undefined,
  spTitleFontSizePx: undefined,
  pcSubTitleFontSizePx: undefined,
  spSubTitleFontSizePx: undefined,
  align: "left",
})

const { width: windowWidth } = useWindowSize()
const md = computed(() => windowWidth.value >= 768)

const spTitleStyle = computed(() => {
  if (!props.spTitleFontSizePx) return {}
  return {
    "font-size": `${props.spTitleFontSizePx}px`,
  }
})

const spSubTitleStyle = computed(() => {
  if (!props.spSubTitleFontSizePx) return {}
  return {
    "font-size": `${props.spSubTitleFontSizePx}px`,
  }
})
const sanitizedTitle = computed(() => sanitizeHtmlContent(props.title))
const sanitizedSubTitle = computed(() => sanitizeHtmlContent(props.subTitle))
</script>

<style scoped module lang="scss">
.root {
  @include container-lg;
  .sp_container {
    color: $primary;
    .title {
      font-size: $font-size-24;
      font-weight: 600;
    }

    .sub_title {
      font-size: $font-size-11;
      font-weight: 400;
    }
  }
}
</style>
